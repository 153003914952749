import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { SupportSection } from 'modules/support/components';
import { FeaturesSection } from 'modules/shared/components';
import { BusinessPromoSection } from 'modules/business/components';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';
import { UserReviewsSection, useUserReviewsExperiment } from 'components/common/UserReviewsSection';
import { IdentifierForm } from 'components/common/IdentifierForm';
import { FeatureChecklist, CompactCallToActionSection } from 'modules/shared';
import { HorizontalTrustBanner } from '../common/HorizontalTrustBanner';
import { VideoReviewSection } from '../common/VideoReviewSection';
import { BlogSection } from '../common/BlogSection';
import { StepsSection } from '../common/StepsSection';
import { Hero, type HeroProps } from '../common/Hero';
import { FeaturesCards } from './FeaturesCards';
import { MarketLeadersSection } from './MarketLeadersSection';
import { StatFactsIllustration } from './StatFactsIlustration';
import styles from './HomePage.module.scss';

const useStatisticalFacts = () => {
  const { market, locale } = useRouteData();

  const experimentEnabled = isExistingMarket({
    market,
    markets: [{ britain: 'en-GB' }],
    locale,
  });

  return experimentEnabled;
};

const Page = dynamic(() => import('components/common/Page'), { ssr: false });

const HomePage = () => {
  const { t } = useTranslation(['home', 'common']);
  const showsStatisticalFacts = useStatisticalFacts();
  const featuresSectionRef = useRef<HTMLDivElement>(null);
  const { homeEnabled: userReviewsEnabled } = useUserReviewsExperiment();

  const heroLeftContent: HeroProps['leftContent'] = showsStatisticalFacts
    ? {
        title: <span className="text-h-l flex lg:text-h-xl">{t('statisticalFacts.title')}</span>,
        subtitle: t('statisticalFacts.subtitle'),
        additionalContent: <FeatureChecklist featuresSectionRef={featuresSectionRef} />,
        identifier: (
          <IdentifierForm
            surroundingBackground="mediumDark"
            continueWithoutVinCtaLabel={t('statisticalFacts.noVinCta')}
          />
        ),
      }
    : {
        title: t('landing.seoTitle'),
        subtitle: t('landing.subtitleVin'),
        additionalContent: <FeatureChecklist featuresSectionRef={featuresSectionRef} />,
      };

  const heroRightContent: HeroProps['rightContent'] = showsStatisticalFacts
    ? { illustration: <StatFactsIllustration />, illustrationClassName: 'm-auto' }
    : {
        illustration: <FeaturesCards />,
        illustrationClassName: 'm-auto',
      };

  return (
    <Page
      title={t('common:seo.title')}
      description={t('common:seo.description')}
      breadcrumbs={false}
    >
      <Hero
        color="colorLightBlue200"
        leftContent={heroLeftContent}
        rightContent={heroRightContent}
      />

      <HorizontalTrustBanner />

      <FeaturesSection ref={featuresSectionRef} />

      <StepsSection color="colorLightBlue100" subtitleShown />

      {userReviewsEnabled ? <UserReviewsSection /> : <VideoReviewSection />}

      <CompactCallToActionSection />

      <MarketLeadersSection />

      <BusinessPromoSection />

      <SupportSection containerClassName={styles.supportContainer} />

      <BlogSection />
    </Page>
  );
};

export { HomePage };
