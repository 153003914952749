import { Container, Heading, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import type { ParseKeys } from 'i18next';
import { Section } from 'components/landings/common/Section';
import { MarketList } from './MarketList';

const DESCRIPTION_KEYS: ParseKeys<'home'>[] = [
  // @ts-expect-error TS(2322)
  'homeOptMarketLeadersSection.description.0',
  // @ts-expect-error TS(2322)
  'homeOptMarketLeadersSection.description.1',
];

const MarketLeadersSection = () => {
  const { t } = useTranslation('home');

  return (
    <Section className={`bg-[url('/images/map.png')] bg-cover bg-right bg-no-repeat`}>
      <Container>
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          <div className="flex flex-col gap-2">
            <Heading as="h2" variant="l">
              {t('homeOptMarketLeadersSection.title')}
            </Heading>

            {DESCRIPTION_KEYS.map((key) => (
              <Text key={key} as="p" variant="m">
                {t(key)}
              </Text>
            ))}
          </div>

          <MarketList />
        </div>
      </Container>
    </Section>
  );
};

export { MarketLeadersSection };
